import { Divider, Text, Group, Stack, rem, Flex, Anchor, Image, useMantineTheme } from '@mantine/core';
import Logo from '@/images/pro-full-mark-outlined.svg';
import { FOOTER_ITEMS } from '@/utils/constants';

const Footer = () => {
  const theme = useMantineTheme();
  const itemsPerColumn = 4;
  const columnsCount = Math.ceil(FOOTER_ITEMS.length / itemsPerColumn);

  const columns = Array.from({ length: columnsCount }, (_, index) =>
    FOOTER_ITEMS.slice(index * itemsPerColumn, (index + 1) * itemsPerColumn),
  );

  return (
    <>
      <Divider mt={rem(36)} mx={'-xl'} color={theme.other.colors.white40} />
      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={'xl'}
        justify={{ sm: 'space-between' }}
        px={{ base: '0', md: 'xl' }}
        mt={rem(36)}
      >
        <Stack gap={'lg'}>
          <Flex direction={'row'} align={'center'} gap="md">
            <Image src={Logo} alt="Deepcast Logo" w="auto" mah={rem(36)} />
          </Flex>

          <Text maw={300} lh={1.5} c={theme.other.colors.textLightGray} size="sm">
            Supercharging podcasters. You create it, we help you grow and monetize it.
          </Text>
        </Stack>
        <Group gap={rem(52)} align="flex-start">
          {columns.map((column, index) => (
            <FooterColumn key={index} items={column} />
          ))}
        </Group>
      </Flex>
    </>
  );
};

const FooterColumn = ({ items }: { items: { title: string; link: string }[] }) => {
  return (
    <Stack gap={rem(32)}>
      {items.map((item, index) => (
        <Anchor key={index} href={item.link} target="_blank" size="sm" c="white" fw="700">
          {item.title}
        </Anchor>
      ))}
    </Stack>
  );
};

export default Footer;
