import { Outlet } from '@remix-run/react';
import LandingShell from './landing-shell';

export default function Landing() {
  return (
    <LandingShell>
      <Outlet />
    </LandingShell>
  );
}
