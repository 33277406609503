import { Link, useSubmit } from '@remix-run/react';
import { Anchor, AppShell, Button, Group, Image, rem, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import classes from '@/style/AppShell.module.css';

import Logo from '@/images/pro-full-mark-outlined.svg';
import Footer from './footer';

/**
 * Upper nav + footer for non-dashboard views
 */
const LandingShell = ({ children }: { children: React.ReactNode }) => {
  const theme = useMantineTheme();
  const submit = useSubmit();

  const handleLogin = () => {
    notifications.show({
      message: 'Authenticating...',
    });
    submit(null, { action: '/auth', method: 'post' });
  };

  return (
    <AppShell
      header={{ height: rem(60) }}
      layout="alt"
      withBorder={true}
      p="xl"
      bg="#062B42"
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <AppShell.Header className={classes['landing-page-header']}>
        <Group align="center" justify="space-between" px={theme.spacing.sm} style={{ height: '100%' }}>
          <Group align="center" gap={rem(24)}>
            <Link to="/">
              <Image src={Logo} alt="Deepcast Logo" w="auto" mah={rem(36)} />
            </Link>
            <Group>
              <Anchor href="/pricing" visibleFrom="sm" size="md" c="white" underline="never">
                Pricing
              </Anchor>
              <Anchor href="/help-center" visibleFrom="sm" size="md" c="white" underline="never">
                Help
              </Anchor>
            </Group>
          </Group>

          <Button onClick={handleLogin} variant="filled">
            Log in
          </Button>
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        {children}
        <Footer />
      </AppShell.Main>
    </AppShell>
  );
};
export default LandingShell;
